<script setup>
import { useForm, usePage } from '@inertiajs/vue3';
import { price } from '@/Helpers/formatters';
import route from '@/Helpers/route';

const page = usePage();

defineProps({
    item: Object,
});

const form = useForm({});

const incrementItem = async (id) => form.post(route(`${page.props.routing_group}.cart.increment`, {
    [page.props.routing_key]: page.props.routing_target,
    id: id
}), {
    preserveState: true,
    preserveScroll: true,
    only: ['cart', 'errors'],
});

const decrementItem = async (id) => form.post(route(`${page.props.routing_group}.cart.decrement`, {
    [page.props.routing_key]: page.props.routing_target,
    id: id
}), {
    preserveState: true,
    preserveScroll: true,
    only: ['cart', 'errors'],
});

// const deleteItem = async (id) => form.post(route(`${page.props.routing_group}.cart.delete`, {
//     [page.props.routing_key]: page.props.routing_target,
//     id: id
// }), {
//     preserveState: true,
//     preserveScroll: true,
//     only: ['cart', 'errors'],
// });
</script>

<template>
    <li class="p-3" :dusk="`item-${item.id}`">
        <div class="grid grid-cols-4 gap-x-4 justify-between" :class="{ 'mb-2': item.option_groups.length > 0 }">
            <div class="col-span-3">
                <div class="flex flex-row justify-between">
                    <p>
                        <span dusk="quantity">{{ item.quantity }}</span>x -
                        <span class="font-semibold" dusk="name">{{ item.product.name }}</span>
                    </p>
                </div>

                <ul class="text-sm">
                    <li v-for="group in item.option_groups" :key="group.id">
                        {{ group.group.name }}: <span class="text-gray-600">{{ group.options.map(option => option.option.name).join(', ') }}</span>
                    </li>
                </ul>
            </div>

            <div class="col-span-1">
                <p class="font-medium text-gray-600 dark:text-gray-400 text-right mb-2" dusk="price">{{ price(item.total) }}</p>

                <div class="flex flex-row justify-end gap-2">
                    <button class="w-8 h-8 flex justify-center items-center bg-blue-200 hover:bg-blue-300 text-blue-500 px-2 py-1 aspect-square rounded-lg my-auto" @click="decrementItem(item.id)" dusk="decrement" v-if="! (item.product.is_mandatory && item.quantity === 1)">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fill-blue-500 w-[16px] h-[16px] animate-spin" v-if="form.processing"> <!-- loading -->
                            <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                            <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"/>
                        </svg>

                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="fill-blue-500 w-[16px] h-[16px]" v-if="!form.processing"> <!-- minus -->
                            <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                            <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/>
                        </svg>
                    </button>

                    <button class="w-8 h-8 flex justify-center items-center bg-blue-200 hover:bg-blue-300 text-blue-500 px-2 py-1 aspect-square rounded-lg my-auto" @click="incrementItem(item.id)" dusk="increment">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fill-blue-500 w-[16px] h-[16px] animate-spin" v-if="form.processing"> <!-- loading -->
                            <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                            <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"/>
                        </svg>

                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="fill-blue-500 w-[16px] h-[16px]" v-if="!form.processing"> <!-- plus -->
                            <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                            <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </li>
</template>
